import axios from 'axios';
import logger from 'utils/logger';

const env = process.env.REACT_APP_ENVIRONMENT;
const url =
  env === 'production'
    ? 'https://content.iapp.org'
    : `https://${env}-content.iapp.org`;

export default {
  getSpecificEntry: async function (contentUid, entryUid, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentUid}/entries/${entryUid}?environment=${process.env.REACT_APP_ENVIRONMENT}&locale=${locale}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        logger.error('error getSpecificEntry:', error);
      });
  },
  getSpecificEntryWithRef: async function (
    contentUid,
    entryUid,
    locale = 'en-us',
    ref
  ) {
    return axios
      .get(
        `${url}/content_types/${contentUid}/entries/${entryUid}?environment=${
          process.env.REACT_APP_ENVIRONMENT
        }&locale=${locale}&include[]=${ref.replaceAll(',', '&include[]=')}`
      )

      .then((res) => {
        return res.data;
      })

      .catch((error) => {
        logger.error(error);
        // TODO: bubble up errors and convert react-browser-router to handle error elements
      });
  },
  getAllContentTypes: async function () {
    return axios
      .get(`${url}/content_types?include_count=false`)
      .then((res) => {
        return res.data.content_types;
      })
      .catch((error) => {
        logger.error('ERROR getAllContentTypes: ', error);
      });
  },
  getAllEntries: async function (contentTypeUid, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentTypeUid}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&locale=${locale}&include_fallback=true`
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getAllEntries: ', error);
      });
  },
  getAllEntriesWithRef: async function (contentTypeUid, ref, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentTypeUid}/entries?environment=${
          process.env.REACT_APP_ENVIRONMENT
        }&locale=${locale}&include[]=${ref.replaceAll(
          ',',
          '&include[]='
        )}&include_fallback=true`
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getAllEntriesWithRef: ', error);
      });
  },
  getArticle: async function (
    contentTypeUid,
    articleName,
    locale = 'en-us',
    ref
  ) {
    const formattedArticleName = articleName.replace(/\/+$/, ''); // Remove trailing slashes
    const formattedRefs = ref?.replaceAll(',', '&include[]=');
    const refsParam = `include[]=${formattedRefs}`;
    const envParam = `environment=${env}`;
    const localeParam = `locale=${locale}`;
    const constructedUrl = `${url}/content_types/${contentTypeUid}/entries?${envParam}&${localeParam}&query={"url": "${formattedArticleName}"}&${refsParam}`;
    
    const res = await axios.get(constructedUrl);

    if (
      res.status !== 200 ||
      !res?.data?.entries ||
      res?.data?.entries?.length === 0
    ) {
      throw new Error(
        `Error retrieving article. Status: ${res.status}: ${res.statusText}`
      );
    }

    return res.data.entries;
  },

  getLimitedEntries: function (contentTypeUid, limit, desc, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentTypeUid}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&locale=${locale}&limit=${limit}&desc=${desc}`
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getLimitedEntries: ', error);
      });
  },
  getBanner: function (parent) {
    return axios
      .get(
        `${url}/content_types/${parent}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&query={ "section_header_for_subpages": { "$exists": true } }&only[BASE][]=section_header_for_subpages&include[]=section_header_for_subpages`
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getBanner: ', error);
      });
  },
  checkResource: function (ct, path) {
    return axios
      .get(
        `${url}/content_types/${ct}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&query={"url": "${path}"}&only[BASE][]=uid`
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR checkResource: ', error);
      });
  },
  getUID: function (path) {
    return axios
      .get(
        `${url}/content_types?environment=${process.env.REACT_APP_ENVIRONMENT}&query={"options.url_pattern": "${path}"}&only[BASE][]=uid}`
      )
      .then((res) => {
        return res.data.content_types;
      })
      .catch((error) => {
        logger.error('ERROR getUID: ', error);
      });
  },
  getSchema: function (ct) {
    return axios
      .get(
        `${url}/content_types/${ct}?environment=${process.env.REACT_APP_ENVIRONMENT}`
      )
      .then((res) => {
        return res.data.content_type.schema;
      })
      .catch((error) => {
        logger.error('ERROR getSchema: ', error);
      });
  },
};
