import { extendTheme } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/react';
// import Link from './componentThemes/links';

const Button = defineStyleConfig({
  // The styles all buttons have in common
  baseStyle: {
    fontSize: '20px',
  },
  // Three sizes: small, medium, and large
  sizes: {
    small: {
      fontSize: '18px',
      fontWeight: '600',
      fontVariantLigatures: 'no-common-ligatures',
      px: '32px',
      py: '12px',
      minW: '170px',
      borderRadius: '40px',
    },
    medium: {
      fontSize: '18px',
      fontWeight: '600',
      fontVariantLigatures: 'no-common-ligatures',
      px: '22px',
      py: '17px',
      minW: '210px',
      borderRadius: '40px',
    },
    large: {
      fontSize: '20px',
      fontWeight: '600',
      fontVariantLigatures: 'no-common-ligatures',
      px: '19px',
      py: '23px',
      minW: '240px',
      borderRadius: '40px',
    },
  },
  // Three variants: primary, secondary, and tertiary
  variants: {
    primary: {
      color: '#FFFFFF',
      bg: 'ContrastGreen',
      border: '2px solid ContrastGreen',
      _hover: {
        bg: 'DarkGreen',
        textDecoration: 'none',
      },
    },
    secondary: {
      color: 'DarkGreen',
      bg: '#FFFFFF',
      border: '2px solid DarkGreen',
      _hover: {
        color: '#FFFFFF',
        bg: 'DarkGreen',
        textDecoration: 'none',
      },
    },
    tertiary: {
      color: '#FFFFFF',
      bg: '#000000',
      border: '2px solid #000000',
      _hover: {
        color: 'DarkGreen',
        bg: '#FFFFFF',
        textDecoration: 'none',
      },
    },
  },
  // The default size and variant values
  // defaultProps: {
  //   size: 'large',
  //   variant: 'primary',
  // },
});

const theme = extendTheme({
  // Site Fonts format: 'Primary Font, Backup Font'
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Open Sans',  sans-serif`,
  },
  // Site Colors
  colors: {
    Green190: '#11170D',
    Green170: '#25391A',
    Green150: '#38602D',
    Green130: '#48843B',
    Green100: '#71BF4B',
    Green70: '#99CC69',
    Green50: '#B6D994',
    Green30: '#D3E8BE',
    Green10: '#F1F7EA',
    Blue190: '#080C1E',
    Blue170: '#12284B',
    Blue150: '#2A3D97',
    Blue130: '#1658A5',
    Blue100: '#0078B7',
    Blue70: '#05B4D7',
    Blue50: '#97D8E9',
    Blue30: '#CCEBF3',
    Blue10: '#E6F8FB',
    Purple190: '#200515',
    Purple170: '#31081F',
    Purple150: '#510D34',
    Purple130: '#7D194D',
    Purple100: '#9C1F60',
    Purple70: '#BA6290',
    Purple50: '#CE9EB3',
    Purple30: '#E3CAD4',
    Purple10: '#F5E9EF',
    Red190: '#2D0608',
    Red170: '#5B0C0F',
    Red150: '#881317',
    Red130: '#B6191E',
    Red100: '#E31F26',
    Red70: '#EC5571',
    Red50: '#EF90A2',
    Red30: '#EDCBD2',
    Red10: '#FCE9E9',
    Orange190: '#300D07',
    Orange170: '#601A0E',
    Orange150: '#8F2615',
    Orange130: '#BF331C',
    Orange100: '#EF4023',
    Orange70: '#F16027',
    Orange50: '#F58345',
    Orange30: '#FBBD97',
    Orange10: '#FDECE9',
    Yellow190: '#493707',
    Yellow170: '#926D0F',
    Yellow150: '#C39214',
    Yellow130: '#F4B619',
    Yellow100: '#F9D507',
    Yellow70: '#FADB51',
    Yellow50: '#FCE381',
    Yellow30: '#FCEAA9',
    Yellow10: '#FEFBE6',
    Grey190: '#231F20',
    Grey170: '#262728',
    Grey150: '#4C4D4F',
    Grey130: '#707172',
    Grey100: '#949495',
    Grey70: '#B7B8B9',
    Grey50: '#DBDBDC',
    Grey30: '#EDEDED',
    Grey10: '#F4F4F4',
    Black: '#1E1E1E',
    DarkBlack: '#000000',
    BlackOpaque: '#00000080',
    BlackText: '#1E1E1E',
    ContrastGreen: '#38761E',
    DarkBlue: '#165788',
    BlueGreen: '#51A1AA',
    BlueOpaque: '#1B5F8E80',
    DarkGreen: '#006955',
    DarkGreenOpaque: '#00695580',
    GreenText: '#20390C',
    GreenStroke: '#69BE28',
    GreenLink: '#4A851C',
    Grey1: '#626262',
    Grey2: '#8F8F8F',
    Grey3: '#BCBCBC',
    Grey4: '#E9E9E9',
    Grey5: '#F4F4F4',
    IAPPGreen: '#73BE53',
    LightGreen: '#D2ECBF',
    MedGreen: '#4A851C',
    PinkRaspberry: '#8C0A39',
    PrimaryGreen: '#5E9C44',
    PrimaryGreenOpaque: '#5E9C4480',
    Purple: '#5C1D78',
    Raspberry: '#A51140',
    ValidGreen: '#355F14',
    White: '#FFFFFF',
    WhiteOpaque: '#FFFFFF80',
    DarkGreentoPrimaryGreen: 'linear(to-br,#00695590, #6DB65090 )',
    GradientWhite:
      'linear(to-r, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0))',
    GradientGreen:
      'linear(to-r, rgba(94, 156, 68, 0), rgba(94, 156, 68, 0.33), rgba(94, 156, 68, 0))',
  },

  // Set Breakpoints for Responsiveness
  breakpoints: {
    sm: '320px',
    md: '750px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
  // Setting styles for globally used Tags
  styles: {
    global: {
      // link styles for RTE
      a: {
        color: 'Green130',
        fontWeight: '700',
        _hover: {
          textDecoration: 'underline',
        },
      },
      body: {
        fontSize: '1rem',
        color: 'BlackText',
      },
      // styles for the `h1-h6`
      h1: {
        color: 'BlackText',
        fontSize: '2.5rem',
        fontFamily: 'Lato',
        fontWeight: '900',
        lineHeight: '1.2',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h2: {
        color: 'BlackText',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '2.25rem',
        lineHeight: '1.2',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h3: {
        color: 'BlackText',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '2rem',
        lineHeight: '1.2',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h4: {
        color: 'BlackText',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '1.5rem',
        lineHeight: '1.2',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h5: {
        color: 'BlackText',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '1.25rem',
        lineHeight: '1.2',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h6: {
        color: 'BlackText',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '1rem',
        lineHeight: '1.2',
        fontVariantLigatures: 'no-common-ligatures',
      },
      blockquote: {
        display: 'block',
        fontFamily: 'Open Sans',
        fontWeight: '400',
        fontVariantLigatures: 'no-common-ligatures',
        position: 'relative',
        padding: '0.08em 0em 0.08em .8em',
        marginLeft: '1.5em',
      },
      Heading: {
        h1: {
          // color: 'red',
          fontSize: '40px',
          fontFamily: 'Lato',
          fontWeight: '800',
          lineHeight: '45px',
          fontVariantLigatures: 'no-common-ligatures',
        },
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '36px',
        lineHeight: ' 36px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      Img: {
        loading: 'lazy',
        // margin: '65px',
      },
      Image: {
        loading: 'lazy',
        // margin: '65px',
      },
      // blockquote:before {
      //   fontFamily: 'Lato',
      // },
      //   Table Styles
      th: { border: 'lightgray solid 1px', px: '.5rem' },
      td: { border: 'lightgray solid 1px', px: '.5rem' },
      // img: { margin: '65px' },
    },
  },
  components: {
    Button,
    Input: {
      defaultProps: {
        // focusBorderColor: '#1E1E1E',
        // color: 'red',
      },
      baseStyle: {
        field: {
          // color: 'red',
        },
      },
      variants: {
        outline: {
          field: {
            border: '2px solid',
            borderColor: '#8F8F8F',
            _hover: {
              borderColor: '#1E1E1E',
            },
            _focus: {
              boxShadow: 'none',
            },
            borderRadius: '4px',
            '::placeholder': {
              color: '#8F8F8F',
            },
            // '::-webkit-datetime-edit-text': { color: '#8F8F8F' },
            // '::-webkit-datetime-edit-month-field': { color: '#8F8F8F' },
            // '::-webkit-datetime-edit-day-field': { color: '#8F8F8F' },
            // '::-webkit-datetime-edit-year-field': { color: '#8F8F8F' },
          },
        },
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: '#1E1E1E',
        errorBorderColor: '#A51140',
      },
      variants: {
        outline: {
          field: {
            border: '2px solid',
            borderColor: '#8F8F8F',
            _hover: {
              borderColor: '#1E1E1E',
            },
            _focus: {
              boxShadow: 'none',
            },
            borderRadius: '4px',
          },
        },
      },
    },
  },
});

export default theme;
