import { Box } from '@chakra-ui/react';
import React from 'react';

export default function LeftArrow({ variant, fasize }) {
  // TODO: What is this function even doing??
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-arrow-left-long ${fasize}`}
      // {...resizeTo}
    />
  );
}
