import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SkipNavLink } from '@chakra-ui/skip-nav';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import './index.css';

/*
    StaleTime: controls how long data is considered 'fresh'. Once data is considered stale,
    react-query may refetch it when appropriate
    CacheTime: controls how long inactive (unused) data remains in the cache before
    it is garbage collected. Default is 30 min. For example, when no component is using the 
    query it becomes inactive and the query data will remain in memory for 30 min.

    React query docs
    https://tanstack.com/query/v5/docs/framework/react/overview
*/
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30, // 30 min
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SkipNavLink
          color={'white'}
          position={'absolute'}
          style={{
            padding: '.5rem 1rem',
            border: 'solid white 2px',
            borderRadius: '20px',
            margin: '.5rem',
            color: 'black',
          }}
        >
          Skip to Content
        </SkipNavLink>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);
